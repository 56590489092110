<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container">
          
                <div class="accordion pt-4" id="accordionExample">
                    <div class="card ">
                        <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button">
        What is reQuid?
        </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">

                                <p>reQuid is an investment marketplace that aggregates carefully profiled investment opportunities
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How does reQuid work?

        </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>We partner with Redwoods Capital Limited to carefully profile available investments, assessing the risk, verify the insurance coverage. Then we make this information available for prospective investors to make informed investment decisions. </p>

                                <p class="pt-2">Investments that meet a certain benchmark are aggregated on our platform for investors to participate in. As part of our value, we make the investments available in smaller units that even low-income earners can afford, and provide the benefit for investors to liquidate (sell) their investment before the maturity date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How safe is my money?

        </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Your money saved in your wallet is secure with United Capital Trustees, a member of the United Capital Group, a fiduciary institution licensed by the Securities and Exchange Commission to perform the functions of Trustee in the capital market.
                                </p>

                                <p class="pt-2">Funds invested in products are guaranteed to the extent of guarantee provided by investment provider. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How can I start investing?

                              </button>
                            </h2>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Create an account in less than three minutes on <a style="display:inline; padding:0rem" href="https://market.requid.com/portfolio">market.requid.com</a> Fund your wallet. Visit the marketplace to invest in available opportunities
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >What is the duration of investments in reQuid?

        </button>
                            </h2>
                        </div>
                        <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>The duration of investments vary from provider to provider, but are generally between three to twelve months. However, you can liquidate (sell) your investment before it matures.

                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How can I sell my investments?

                         </button>
                            </h2>
                        </div>
                        <div id="collapseSix" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Visit your Portfolio at <a style="display:inline; padding:0rem" href="https://market.requid.com/portfolio">market.requid.com/portfolio</a> Click on Sell, and your investment will be liquidated.

                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >Do funds in my wallet earn me interest?

        </button>
                            </h2>
                        </div>
                        <div id="collapseSeven" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Yes. Funds in your wallet earn you an annual interest of 10%. We recommend that you maintain a consistent savings habit. In addition, maintaining funds in your wallet help you access investment opportunities more quickly. Most opportunities are high in demand and sell out fast.

                                </p>

                            </div>
                        </div>
                    </div> -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <h2 class="mb-0">
                            <button class="btn btn-link collapsed" type="button">How do I fund my wallet?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseEight" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Go to Wallet at <a style="display:inline; padding:0rem" href="https://market.requid.com/wallet">market.requid.com/wallet</a> Use the Fund Wallet option, and pay with Paystack (card, USSD, online banking) or direct bank transfer to a designated account.

                                </p>

                            </div>
                        </div>
                    </div>

                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >Does reQuid charge me for investing in their platform?

                                 </button>
                            </h2>
                        </div>
                        <div id="collapseNine" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>No, investments are free to access.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button"> Is there a fee for liquidating my investment before maturity?
                                 </button>
                            </h2>
                        </div>
                        <div id="collapseTen" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Yes. We charge a minimal fee of 3%.

                                </p>

                            </div>
                        </div>
                    </div>

                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseEleven2" aria-expanded="false" aria-controls="collapseEight2">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How can I contact reQuid?
        </button>
                            </h2>
                        </div>
                        <div id="collapseEleven2" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>You can send an email to <b>support@requid.com </b>

                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseEleven1" aria-expanded="false" aria-controls="collapseEight1">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >Who owns the investment options in reQuid?

        </button>
                            </h2>
                        </div>
                        <div id="collapseEleven1" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Investments are provided by agrotech companies that have been carefully profiled by our partner, Redwoods Capital Ltd

                                </p>

                            </div>
                        </div>
                    </div>
                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEight">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How many times can I invest in a year?

        </button>
                            </h2>
                        </div>
                        <div id="collapseEleven" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>You can invest as many times as you wish, and you can liquidate as many times as you wish.

                                </p>

                            </div>
                        </div>
                    </div>
                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseEight">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How much returns do I get?

        </button>
                            </h2>
                        </div>
                        <div id="collapseTwelve" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Returns vary from investment to investment. Visit the Marketplace for details.

                                </p>

                            </div>
                        </div>
                    </div>
                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseEight">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How long does it take to receive my money after withdrawal?

        </button>
                            </h2>
                        </div>
                        <div id="collapseThirteen" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>
                                    Typically, between 1 to 2 working days after withdrawal.

                                </p>

                            </div>
                        </div>
                    </div>
                    <!-- new card   -->
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" data-target="#collapseFourtheen" aria-expanded="false" aria-controls="collapseEight">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" >How do I update my account details?

        </button>
                            </h2>
                        </div>
                        <div id="collapseFourtheen" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                <p>To protect your account, we do not support you changing your bank account details instantly. To change your account details, please send an email to <b>support@requid.com</b>

                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'
import Footer from '../../partials/Footer'
import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";
import searchProduct from "../SearchProduct";
import http from "../../../http/index";
import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {

        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        searchProduct
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,
            bankNames: state => state.auth.bankNames,
            editProfileLoader: state => state.auth.editProfileLoader,
            editBankDetailLoader: state => state.auth.editBankDetailLoader,
            bankNames: state => state.auth.bankNames,
            editProfile: state => state.auth.editProfile,
            editBankDetail: state => state.auth.editBankDetail,

        }),

    },
    mounted() {
   let heading = {

            main: this.tran.faq,
        }
        this.$store.commit('market/savePageHeading', heading)
        this.tran = trans;

    },
}
</script>

<style scoped>

</style>
