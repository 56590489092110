<template>
<div>
    <Faq />
</div>
</template>

<script>
import Faq from '../../components/dashboard/helpSupport/Faq.vue'
export default {
    components: {
        Faq
    }
}
</script>

<style>
    